<template>
<div class="flex-display">
  <!-- 校验数字和手机号 -->
  <search-bar-new
    :placeholder-text="$t('请输入完整的电话号码')"
    :str="searchStr"
    :showScreen="false"
    :showCategory="false"
    @input="onInput"
    @search="onSearch"/>
</div>
</template>
<script>
import SearchBarNew from '@/components/search-bar-new'
const reg = new RegExp(/^[0-9]\d{10}$/)
export default {
  components:{ SearchBarNew },
  data(){
    return {
      searchStr: '',
      inputValue: '',
      totalCount: 0
    }
  },
  computed: {
    getUserInfo() {
      return this.$store.getters.userInfo
    },
  },
  methods:{
    onInput(value){
      this.inputValue = value
    },
    onSearch(){
      if (this.inputValue && !reg.test(this.inputValue)){
        return this.$toast(this.$t('请输入完整的电话号码'))
      }
      this.$router.push({
        path: '/shop-clue-search-list',
        query: {
          searchStr: this.inputValue
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.flex-display{
  display: flex;
}
</style>